<template>
    <!--  -->
    <div v-if="formData.type === 'affiliate'" class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 py-3 items-center">
        <!--  -->
        <div class="bg-white w-full rounded-lg shadow pb-20">
            <!--  -->
            <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
                <!--  -->
                <div class="flex w-full">
                    <!--  -->
                    <button :class="$colors.primary" class="rounded-full flex justify-center items-center w-8 h-8 mr-3" @click="RouterBack()">
                        <i class="material-icons">chevron_left</i>
                    </button>
                    <!--  -->

                    <!--  -->
                    <div class="text-xl font-bold text-green-600">Offre details</div>
                    <!--  -->
                </div>
                <!--  -->
            </div>
            <!--  -->

            <!--  -->
            <div id="detail">
                <!--  -->
                <div class="flex pr-5 pl-5 space-x-3">
                    <!--  -->
                    <div class=" justify-between shadow w-full h-auto p-4 my-1 rounded-lg border-gray-100">
                        <!--  -->
                        <div class="w-full">
                            <!--  -->
                            <div class="flex">
                                <span class="rounded-full h-12 w-12 bg-gray-100"> <i class="material-icons p-3 text-green-400">line_weight</i> </span>
                                <!--  -->

                                <!--  -->
                                <div class="ml-5 w-full space-y-2">
                                    <!--  -->
                                    <div class="text-base mt-2 mb-2 font-semibold text-gray-700 uppercase">{{ formData.name }}</div>
                                    <div class="flex flex-wrap gap-20">
                                        <img class="w-140 h-140 rounded-mdx overflow-hidden ng-star-inserted" :src="formData.picture" :alt="formData.name">
                                        <div class="flex flex-col">
                                            <h2 class="text-xl font-bold text-black dark:text-white ng-star-inserted uppercase">{{ formData.name }}</h2>
                                            <div class="mt-8 mb-24">
                                                <button  class="mod-animation-button mod-base-button mod-flat-button ng-star-inserted">
                                                    <a :href="formData.details[0].affiliate.landingPage" target="_blank" class="mod-base-button-inner"><span> Offre page </span><span class="mod-icon"> <img width="25" height="25" src="https://img.icons8.com/glyph-neue/64/FFFFFF/up-right-arrow.png" alt="up-right-arrow"/> </span></a>
                                                </button>
                                            </div>
                                            <h2 class="font-bold text-black dark:text-white ng-star-inserted flex items-center">
                                                <span class="ciblage">Ciblage: </span>
                                                <span v-for="(detialPr, r) in $f.removeCountryByTwoArray(formData.details,currentUser.countries)" :key="r">
                                                    <img :src="'https://static.dwcdn.net/css/flag-icons/flags/4x3/' + detialPr.country.toLowerCase() + '.svg'" class="w-4 h-4 rounded-full ml-2">
                                                </span>
                                            </h2>
                                            <div>
                                                <div v-if="currentUser.type === 'Seller'" class="flex items-center ng-star-inserted mt-4"> 
                                                    <span v-if="checkingRequestOffre && checkingRequestOffre.status" class="ng-star-inserted">
                                                        <template v-if="checkingRequestOffre.status === 'approved'">
                                                            <svg style="color: rgb(42, 177, 102);" class="fill-current inline-block mr-1" width="22" height="22" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-5.049 10.386 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z" fill-rule="nonzero"/></svg>
                                                            <span style="color: rgb(42, 177, 102)">Vous êtes autorisé à exécuter cette offre.</span>
                                                        </template>
                                                        <template v-if="checkingRequestOffre.status === 'rejected'">
                                                            <svg style="color: rgb(215, 21, 21);" class="fill-current inline-block mr-1" width="22" height="22" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m2.095 19.886 9.248-16.5c.133-.237.384-.384.657-.384.272 0 .524.147.656.384l9.248 16.5c.064.115.096.241.096.367 0 .385-.309.749-.752.749h-18.496c-.44 0-.752-.36-.752-.749 0-.126.031-.252.095-.367zm9.907-6.881c-.414 0-.75.336-.75.75v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5c0-.414-.336-.75-.75-.75zm-.002-3c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z" fill-rule="nonzero"/></svg>                                     
                                                            <span style="color: rgb(215, 21, 21)">Vous n'êtes pas autorisé à exécuter cette offre.</span>
                                                        </template>
                                                        <template v-if="checkingRequestOffre.status === 'pending'">
                                                            <svg style="color: rgb(215, 139, 21);" class="fill-current inline-block mr-1" width="22" height="22" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M23 10v4h-22v-4h22zm1-1h-24v6h24v-6zm-8 2h-14v2h14v-2z"/></svg>                                     
                                                            <span style="color: rgb(215, 139, 21)">Offre encore de validation.</span>
                                                        </template>
                                                      
                                                    </span>
                                                    <span v-else> 
                                                        <button style="font-size: 15px;" :disabled="loadingOffre" @click="RequestOffre"  class="border mt-2 border-orange-700 bg-orange-600  hover:bg-orange-800 rounded  font-semibold rounded-md text-white py-2 px-4  focus:outline-none loidingconfirm relative"><!---->
                                                            <loading :active.sync="loadingOffre" :is-full-page="fullPage"></loading>
                                                            <img src="https://img.icons8.com/material-sharp/48/FFFFFF/parallel-tasks.png" class="mx-2 img-storeino inline-block" style="height: 23px;"> Request offre 
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                            </div>
                           
                       
                            <div class="ml-5 mb-16 mt-5"><div class="flat-sub-title flex h-36 items-center"><h2 class="text-sub text-black font-semibold dark:text-white">Commissions</h2></div></div>
                            <div class="ml-5 overflow-x-auto">
                                <table v-for="(detialPr, r) in $f.removeCountryByTwoArray(formData.details,currentUser.countries)" :key="r" class="cdk-table flat-table"  style="--flat-table-option-last-header-cell-right-spacing: 1.5rem; --flat-table-option-last-cell-right-spacing: 1.5rem;">
                                    <tr role="row"  class="cdk-header-row flat-table-header-row ng-star-inserted">
                                        <th  class="cdk-header-cell cdk-column-name flat-column-name flat-table-header-cell ng-star-inserted"><span>Objectif ({{ detialPr.warehouse.name }} <img :src="'https://static.dwcdn.net/css/flag-icons/flags/4x3/' + detialPr.country.toLowerCase() + '.svg'" class="w-4 h-4 rounded-full inline-block ml-1">)</span></th>
                                        <th  class="cdk-header-cell cdk-column-payout flat-column-payout flat-table-header-cell ng-star-inserted"><span>Quantity in Stock</span></th>
                                        <th  class="cdk-header-cell cdk-column-payout flat-column-payout flat-table-header-cell ng-star-inserted"><span>Confirmation rate</span></th>
                                        <th  class="cdk-header-cell cdk-column-payout flat-column-payout flat-table-header-cell ng-star-inserted"><span>Delivery rate</span></th>
                                        <th  class="cdk-header-cell cdk-column-payout flat-column-payout flat-table-header-cell ng-star-inserted"><span>Prix</span></th>
                                        <th  class="cdk-header-cell cdk-column-payout flat-column-payout flat-table-header-cell ng-star-inserted"><span>Commission</span></th>
                                    </tr>
                                    <tr role="row" class="cdk-row flat-table-row ng-star-inserted">
                                        <td class="cdk-cell cdk-column-name flat-column-name flat-table-cell ng-star-inserted"><span class="whitespace-pre font-medium">Delivered</span></td>
                                        <td class="cdk-cell cdk-column-name flat-column-name flat-table-cell ng-star-inserted"><span class="whitespace-pre font-medium">{{ detialPr.quantity.inStock }}</span></td>
                                        <td class="cdk-cell cdk-column-name flat-column-name flat-table-cell ng-star-inserted"><span class="whitespace-pre font-medium">{{ detialPr.affiliate.confirmationRate }}%</span></td>
                                        <td class="cdk-cell cdk-column-name flat-column-name flat-table-cell ng-star-inserted"><span class="whitespace-pre font-medium">{{ detialPr.affiliate.deliveryRate }}%</span></td>
                                        <td class="cdk-cell cdk-column-name flat-column-name flat-table-cell ng-star-inserted"><span class="whitespace-pre font-medium">{{ detialPr.price }} <sup>{{ detialPr.warehouse.currency }}</sup></span></td>
                                        <td class="cdk-cell cdk-column-payout flat-column-payout flat-table-cell ng-star-inserted">
                                            <div class="flex items-center whitespace-pre">
                                                <span class="goals-type-chip">
                                                    </span><span class="ml-8"> <b>{{ detialPr.commission }}</b> <sup>{{ detialPr.warehouse.currency }}</sup> </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-for="(upsel, l) in detialPr.upsells" :key="l" role="row" class="cdk-row flat-table-row ng-star-inserted upsells" :class="l===0?'upsell-td':''">
                                        <td class="cdk-cell cdk-column-name flat-column-name flat-table-cell ng-star-inserted"><span class="whitespace-pre upsel-el font-medium">Delivered by Upsell ({{upsel.quantity}} quantity)</span></td>
                                        <td class="cdk-cell cdk-column-name flat-column-name flat-table-cell ng-star-inserted"></td>
                                        <td class="cdk-cell cdk-column-name flat-column-name flat-table-cell ng-star-inserted"></td>
                                        <td class="cdk-cell cdk-column-name flat-column-name flat-table-cell ng-star-inserted"></td>
                                        
                                        <td class="cdk-cell cdk-column-name flat-column-name flat-table-cell ng-star-inserted"><span class="whitespace-pre font-medium">{{ upsel.price }} <sup>{{ detialPr.warehouse.currency }}</sup></span></td>
                                        <td class="cdk-cell cdk-column-payout flat-column-payout flat-table-cell ng-star-inserted">
                                            <div class="flex items-center whitespace-pre">
                                                <span class="goals-type-chip">
                                                    </span><span class="ml-8"> <b>{{ upsel.commission }}</b> <sup>{{ detialPr.warehouse.currency }}</sup> </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="ml-5 mb-16 mt-5"><div class="flat-sub-title flex h-36 items-center"><h2 class="text-sub text-black font-semibold dark:text-white">Description</h2></div></div>
                            <section class="ml-5 description">
                                <div  class="inner-html overflow-hidden max-h-[30rem]">
                                    <!-- <h2 class="font-bold">SKU:&nbsp;<span style="color: rgb(58, 58, 58);">{{ formData.id }}</span></h2> -->
                                   <span v-html="formData.description"></span>
                                </div>
                            </section>
                            <template v-if="currentUser.type === 'Seller' && checkingRequestOffre && checkingRequestOffre.status && checkingRequestOffre.status === 'approved'">
                                <div class="ml-5 mb-16 mt-5"><div class="flat-sub-title flex h-36 items-center"><h2 class="text-sub text-black font-semibold dark:text-white">Lien de suivi</h2></div></div>
                                <section  class="ml-5 p-5 referral  rounded-md animation-pulse" >
                                    <div class="ng-star-inserted">
                                        <div class="flex flex-col ">
                                            <div class="offer-link-builder__header tablet:flex-row  flex flex-col items-start gap-8 px-2 empty:hidden ">
                                                <p  class="text-tiny ">Il s'agit de votre lien de suivi par défaut qui peut être utilisé pour promouvoir le site Offer.</p>
                                            </div>
                                            <div  class="px-2 referral-link ng-star-inserted" style="--text-size: 15px; --text-container-height: 44px;">
                                                <div class="w-full"><!---->
                                                    <div v-for="(detialPr, r) in $f.removeCountryByTwoArray(formData.details,currentUser.countries)" :key="r" class="text-container flex items-center justify-between rounded-md px-5 dark:border-gray-860 dark:bg-gray-890 border border-gray-500 bg-white mt-4 bg-gray-300">
                                                        <span  class="referral-link__url overflow-x-hidden text-gray-800 dark:text-white pr-4">  <img :src="'https://static.dwcdn.net/css/flag-icons/flags/4x3/' + detialPr.country.toLowerCase() + '.svg'" class="w-4 h-4 rounded-full"> </span>
                                                        <span  class="referral-link__url w-full overflow-x-hidden text-gray-800 dark:text-white truncate"> {{ generaterefLink(detialPr.affiliate.landingPage,detialPr.country) }} </span>
                                                        <div class="referral-link__copy flat-copy-button ng-star-inserted">
                                                            <button class="mdc-button mat-mdc-button flat-button-width-icon-single mat-unthemed mat-mdc-button-base" @click="Copy(generaterefLink(detialPr.affiliate.landingPage,detialPr.country))">
                                                                <span class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span>
                                                                <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                <path d="M7.5 1.66669H15.8333C16.75 1.66669 17.5 2.41669 17.5 3.33335V13.3334C17.5 13.7917 17.125 14.1667 16.6667 14.1667C16.2083 14.1667 15.8333 13.7917 15.8333 13.3334V4.16669C15.8333 3.70835 15.4583 3.33335 15 3.33335H7.5C7.04167 3.33335 6.66667 2.95835 6.66667 2.50002C6.66667 2.04169 7.04167 1.66669 7.5 1.66669ZM4.16667 5.00002H12.5C13.4167 5.00002 14.1667 5.75002 14.1667 6.66669V16.6667C14.1667 17.5834 13.4167 18.3334 12.5 18.3334H4.16667C3.25 18.3334 2.5 17.5834 2.5 16.6667V6.66669C2.5 5.75002 3.25 5.00002 4.16667 5.00002ZM5 16.6667H11.6667C12.125 16.6667 12.5 16.2917 12.5 15.8334V7.50002C12.5 7.04169 12.125 6.66669 11.6667 6.66669H5C4.54167 6.66669 4.16667 7.04169 4.16667 7.50002V15.8334C4.16667 16.2917 4.54167 16.6667 5 16.6667Z" fill="#494D56"></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="note mt-5 border-2 bx--inline-notification--info ibmdocs-note bx--inline-notification bx--inline-notification--low-contrast margin-top-1 margin-bottom-1 border-3 border-dashed border-black">
                                            <div class="bx--inline-notification__details">
                                                <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" aria-hidden="true" class="bx--inline-notification__icon" style="will-change: transform;"><path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,5a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,16,7Zm4,17.12H12V21.88h2.88V15.12H13V12.88h4.13v9H20Z"></path></svg>
                                                <div class="bx--inline-notification__text-wrapper">
                                                    <div class="bx--inline-notification__subtitle">
                                                        <span class="notetitle ibm-bold ibm-textcolor-blue-50 background-blue bx--inline-notification__title ibm-padding-top-1 ibm-padding-bottom-1 padding-left-1 padding-right-1">Note:</span>
                                                         Si vous voulez travailler avec ton propre landing page:
                                                        <br> Tu suivre les même étapes de <b>l'Integration Google Sheet </b> avec les autre plateform (Storeino, Shopify, YouCan, Wordpress ...) <br> 
                                                        Dans ce cas, notre identifiant de produit est <b>{{ checkingRequestOffre.productObject.id }}</b> <br>
                                                        <strong>Note</strong>: Tu peux aussi faire un prix different que le Prix de base , danc ce que la votre <b>commission</b> sera calculer avec cette condition: ((Votre prix - Prix de base) + commission de base)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <div class="ml-5 mb-16 mt-5"><div class="flat-sub-title flex h-36 items-center"><h2 class="text-sub text-black font-semibold dark:text-white">Contenus publicitaires</h2></div></div>
                                <section class="ml-5 meta">
                                    <div class="overflow-x-auto">
                                        <table  class="cdk-table flat-table"  style="--flat-table-option-last-header-cell-right-spacing: 1.5rem; --flat-table-option-last-cell-right-spacing: 1.5rem;">
                                            <tr role="row"  class="cdk-header-row flat-table-header-row ng-star-inserted">
                                                <th  class="cdk-header-cell cdk-column-payout flat-column-payout flat-table-header-cell ng-star-inserted"><span>Contenus publicitaire </span></th>
                                                <th  class="cdk-header-cell cdk-column-payout flat-column-payout flat-table-header-cell ng-star-inserted"><span>Link Drive</span></th>
                                            </tr>
                                            <tr v-for="(detialPr, r) in $f.removeCountryByTwoArray(formData.details,currentUser.countries)" :key="r" role="row" class="cdk-row flat-table-row ng-star-inserted">
                                                <td class="cdk-cell cdk-column-name flat-column-name flat-table-cell ng-star-inserted"><span class="whitespace-pre font-medium">Creative Videos (<img :src="'https://static.dwcdn.net/css/flag-icons/flags/4x3/' + detialPr.country.toLowerCase() + '.svg'" class="w-4 h-4 rounded-full inline-block">)</span></td>
                                                <td class="cdk-cell cdk-column-name flat-column-name flat-table-cell ng-star-inserted"><a :href="detialPr.affiliate.linkDrive" target="_blank" class="cursor-pointer whitespace-pre font-medium"><svg class="fill-current inline-block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z"/></svg></a></td>
                            
                                            </tr>
                                        </table>    
                                    </div>
                                </section>
                            </template>
                          
                        </div>
                     
                    </div>
                    <!--  -->

                   
                </div>

                
                <!--  -->

  
                <!--  -->
            </div>
            <!--  -->
        </div>
        <!--  -->
    </div>
    <!--  -->
</template>
<script>
export default {
        props: {
            currentUser: { type: Object},
            idWharhouse: { type: Object}
        },
        data() {
            return {
                loading:false,
                loadingOffre:false,
                fullPage:false,
                formData: {
                    seller:{},
                    type:"",
                    details: [],
                },
                checkingRequestOffre:null
            };
        },
        async mounted() {
            if (this.$route.params.id) {
                const {content:data} = await this.$server.get("products", {id: this.$route.params.id,type:'affiliate'});
                console.log('data-------',data)
                if(data.type !== 'affiliate') return this.$toast.warning("Une erreur c'est produit, ce produit c'est un produit d'affiliate");
                this.formData = data;
                if(this.currentUser.type === "Seller"){
                    const {content:dataOffre} = await this.$server.get("affiliates", {product: this.$route.params.id,notPopulate:true});
                    if(dataOffre && dataOffre._id) this.checkingRequestOffre=dataOffre;
                    console.log('dataOffre-------',dataOffre)
                }  
            }
          
        },
        computed: {
          
            
        },
        methods: {
            RouterBack: function() {
                this.$router.back();
            },
            Copy(text){
               console.log('text')
               const vm=this
                this.$copyText(text).then(function (e) {
                    vm.$toast.success('Copied'); 
                    console.log(e)
                }, function (e) {
                    vm.$toast.warning('Can not copy'); 
                    console.log(e)
                })
            },
            generaterefLink(link,country){
                if(link){
                    if (link.substring(link.length-1) == "/") link = link.substring(0, link.length-1);
                    link+=`?rmexpress=${this.currentUser.api.ref}-${this.checkingRequestOffre.productObject.id}-${country}`;
                    return link;
                }else return "";
               
            },
            async RequestOffre() {
                this.$confirm("Êtes-vous sûr de demander cette offre ?").then(async (res) => {
                    if (res) {
                        this.loadingOffre=true;
                        const { content: dataAffiliate } = await this.$server.create("affiliates",this.formData);
                        this.loadingOffre=false;
                        console.log('dataAffiliate---------',dataAffiliate)
                        if(dataAffiliate && dataAffiliate._id){
                            this.checkingRequestOffre=dataAffiliate;
                            return this.$toast.success("Votre demande a ete envoyé avec succès");
                        }else return this.$toast.warning(dataAffiliate);
                    }
               });
            },
            async getUser() {
                const res = await this.$me;
                this.currentUser = res.content;
            },
            async getSourcingsHistories() {
                const res = await this.$server.search("sourcingsHistories", {sourcing:this.$route.params.id});
                if (res.content.results) {
                    this.sourcingsHistories = res.content.results;
                    this.sourcingsHistories.total = res.content.total;
                    this.sourcingsHistories.lastpage = res.content.last_page;
                    this.sourcingsHistories.per_page = res.content.per_page;
                    this.sourcingsHistories.currentpage = res.content.current_page;
                } else this.sourcingsHistories = [];
            },
            async paginatation(direction){
                var page=0;
                if(direction == "next"){
                    page=++this.paginate.currentpage;
                }else{
                    page=--this.paginate.currentpage;
                }
                const filters = {
                    page: page,
                    limit: this.limit,
                };
                this.getSourcingsHistories(filters);
            },
            QuantityTotalOrPrices(type,data){
                var value=0;
                for (let i in data) {
                    if(type=='qte') value+=data[i].quantity;
                    else if(type=='fees') value+=data[i].price;
                    else if(type=='feesMAD') value+=data[i].priceDH;
                    else if(type=='priceMAD')  value+=(parseFloat(data[i].priceDH)*parseFloat(data[i].quantity));
                    else value+=(parseFloat(data[i].price)*parseFloat(data[i].quantity));
                    
                }
                return value;
            },
            ShowPopUpInformation(index) {
                let modal_id = "modal_" + index;
                this.$refs[modal_id][0].showModal();
            },
            async closePopUpInformation(index) {
                let modal_id = "modal_" + index;
                this.$refs[modal_id][0].close();
            },
        },
    };
</script>

<style scoped>
 .flat-table-row{
    border-bottom-color: #323d48;
    border-bottom-width: 1px;
    border-top-color: #333f4b;
    border-top-width: 1px;
}
.referral{
    background-color: #ff5b2d2e;
}
.text-tiny {
  font-size: 13px;
  line-height: 16px;
}
.text-container{
  height: 44px;
}
.text-container span{
  font-size: 15px;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.referral-link__copy .flat-button-width-icon-single {
  height: 1.75rem !important;
  width: 1.75rem !important;
  padding: .25rem !important;
  min-width: fit-content;
  border-radius: .25rem;
  line-height: 1rem;
  text-decoration: none;
}
.mdc-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: none;
  outline: none;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  background: rgba(0,0,0,0);
  cursor: pointer;
}
.description{
    padding: 10px;
    background-color: #ff5b2d2e;
}
.upsel-el{
    border-left: 3px solid black;
    padding-left: 5px;
    margin-left: 10px;
}
.upsell-td{
    border-top: 2px dashed black;
}
.upsells td{
    padding: 0.5rem 0.938rem !important;
}
.ciblage{
    font-size: 15px;
}
.bx--inline-notification__icon{
    fill: #ff5b2d;
}
.bx--inline-notification {
    border-left: 3px solid #ff5b2d;
}
.flat-table {
  position: relative;
  width: 100%;
  font-family: Inter,sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
.flat-table .flat-table-header-row {
  height: 2.5rem;
  background-color: #ff5b2d2e;
}
.dark .flat-table .flat-table-header-cell {
  background-color:#2c313c;
}
.flat-table tr th:first-child {
  padding-left: 0.5rem;
}
.flat-table .flat-table-header-cell {
  white-space: nowrap;
  text-align: left;
  font-weight: 400;
  color: #6b7780;
}
.flat-table tr th {
  padding: 0 0.938rem;
}
.flat-table tr td:first-child {
  padding-left:  0.5rem;
}
.flat-table tr td {
  padding: 1rem 0.938rem;
  vertical-align: middle;
}
.whitespace-pre {
  white-space: pre;
}
.ml-8 {
  margin-left: .5rem;
}
.mb-16 {
  margin-bottom: 1rem;
}
.h-36 {
  height: 2.25rem;
}
.text-sub {
  font-size: 18px;
  line-height: 21.78px;
}
.rounded-mdx {
  border-radius: 8px;
}
.w-140 {
  width: 12.75rem;
}
.h-140 {
  height: 12.75rem;
}
.text-xl {
  font-size: 24px;
  line-height: 32px;
}
.mt-8 {
  margin-top: .5rem;
}
.mb-24 {
  margin-bottom: 1rem;
}
.mod-base-button{
    transition: background-color .4s ease-out;
    padding-right: .5rem;
  padding-left: .75rem;
  z-index: 2;
  background-color: #292e3a;
  color: #fdf9f9;
  position: relative;
  display: inline-flex;
  overflow: hidden;
  width: fit-content;
  min-width: 2.25rem;
  height: 2.25rem;
  align-items: center;
  border-radius: .375rem;
  -webkit-box-shadow: 0 0 0 .2rem transparent;
  -moz-box-shadow: 0 0 0 .2rem transparent;
  font-size: .875rem;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
}
.mod-base-button-inner {
  display: inline-flex;
  align-items: center;
  column-gap: .5rem;
  visibility: visible;
  font-size: 14px;
  line-height: 20px;
}
</style>